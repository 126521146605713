
/* src/styles/tailwind.css */
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

/* Add your custom styles here */

/* src/styles/tailwind.css */
@keyframes moveRight {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(95%);
    }
  }
  
  @media (min-width: 1024px) {
    .moving-image {
      animation: moveRight 3s forwards; /* Adjust the duration as needed */
    }
  }
  

/* src/styles/tailwind.css */
/* src/styles/tailwind.css */
.about-me-hover:hover {
    color: rgb(205, 134, 128); /* Change to your desired hover color */
  }
  
  .text-color {
    color:  rgb(26, 230, 209)
  }
  .sahyadri-color{
    color:rgb(83, 228, 115)
  }
  /* Adjust the duration and other properties as needed */
  @keyframes glow {
    0% {
      box-shadow: 0 0 10px 0px #ffffff, 0 0 20px 0px #ffffff, 0 0 30px 0px #ffffff;
    }
    100% {
      box-shadow: 0 0 20px 0px #ffffff, 0 0 30px 0px #ffffff, 0 0 40px 0px #ffffff;
    }
  }
  
  .hover\:glow-and-tilt:hover {
    animation: glow 1s infinite alternate;
  }
  